<template>
  <el-container>
    <el-main>
      <h1>直播控制台</h1>
      <div class="consoleTop">
        <div class="left">
          <img :src="roomInfo.share_img_url" alt="" />
          <div class="leftInfo">
            <p class="roomTitle">{{ roomInfo.title }}</p>
            <p class="time">开播时间：{{ roomInfo.starttimeDate }}——{{ roomInfo.endtimeDate }}</p>
            <p class="">房间号：{{ roomInfo.room_id }}</p>
            <div>
              <span>主播：{{ roomInfo.nick_name }}</span>
              <span>微信号：{{ roomInfo.wechat }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            <p class="title">订阅数</p>
            <p class="num">{{ !roomInfo.fellow ? 0 : roomInfo.fellow }}</p>
          </div>
        </div>
      </div>
      <div class="liveStatus">
        {{
          roomInfo.live_status === 102
            ? '直播未开始'
            : roomInfo.live_status === 101
            ? `直播中  ${alreadyStartTimeHour}:${alreadyStartTimeMin}:${alreadyStartTimeSecond}`
            : roomInfo.live_status === 103
            ? '直播已结束'
            : roomInfo.live_status === 104
            ? '已禁播'
            : roomInfo.live_status === 105
            ? '直播已暂停'
            : roomInfo.live_status === 106
            ? '直播异常'
            : roomInfo.live_status === 107
            ? '直播已过期'
            : '--'
        }}
      </div>
      <el-tabs v-model="activeName" @tab-click="hanldeChange">
        <el-tab-pane label="直播商品" name="0">
          <el-button style="margin-bottom: 20px" type="primary" size="small" @click="importGoods"
            >导入商品</el-button
          >
          <el-table
            :cell-style="() => 'text-align:center'"
            :data="liveGoodsList"
            :header-cell-style="{ 'background-color': '#F8F9FA', 'text-align': 'center' }"
          >
            <template slot="empty">
              <No />
            </template>
            <el-table-column label="商品信息">
              <template v-slot="{ row }">
                <div class="goodsItemInfo">
                  <img :src="row.cover_img" alt="" />
                  <div class="left">
                    <div class="goodsName">{{ row.name }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="价格" prop="price"> </el-table-column>
            <el-table-column label="状态">
              <template v-slot="{ row }">
                {{ row.status === 0 ? '已下架' : '上架中' }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template v-slot="{ row }">
                <el-button type="text" @click="delLiveGoods(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging
            :total="total"
            :page="pageNums"
            :pageNum="page"
            @updatePageNum="updatefirstLog"
          ></Paging>
        </el-tab-pane>
        <el-tab-pane label="直播数据" name="1">
          <div class="liveDataBox">
            <div class="topData">
              <div class="title">效果总览</div>
              <div class="data">
                <div class="dataItem" v-for="(item, index) in dataArr" :key="index">
                  <div>{{ item.name }}</div>
                  <div>{{ item.data }}</div>
                </div>
              </div>
            </div>
            <div class="botTable">
              <div class="top">
                <div class="title">交易数据</div>
                <div class="updateTime">数据更新时间：{{ selectTime }}</div>
                <div class="refash" @click="getLiveInfo(1)">刷新</div>
              </div>
              <div class="bot">
                <el-table
                  :cell-style="() => 'text-align:center'"
                  :data="orderList"
                  :header-cell-style="{ 'background-color': '#F8F9FA', 'text-align': 'center' }"
                >
                  <template slot="empty">
                    <No />
                  </template>
                  <el-table-column label="商品信息">
                    <template v-slot="{ row }">
                      <div class="goodsInfo">
                        <img :src="row.cover_img" alt="" />
                        <div class="left">
                          <div class="goodsName">{{ row.name }}</div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="点击商品详情用户数" prop="person_num"> </el-table-column>
                  <el-table-column label="点击商品详情次数" prop="click_num"> </el-table-column>
                  <el-table-column label="支付用户数" prop="pay_num"> </el-table-column>
                  <el-table-column label="商品销量" prop="sales"> </el-table-column>
                  <el-table-column label="直播间支付金额" prop="pay_amount"> </el-table-column>
                  <el-table-column label="操作">
                    <template v-slot="{ row }">
                      <el-button type="text" @click="lookOrder(row)">查看订单</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <Paging
                  :total="dataTotal"
                  :page="dataPage"
                  :pageNum="dataRow"
                  @updatePageNum="updateSecondLog"
                ></Paging>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-dialog
        title="选择直播商品库"
        :visible.sync="chooseGoddsFlag"
        width="1000px"
        @close="handleClose"
      >
        <div class="chooseGoodsBox">
          <div class="top searchBox">
            <div class="searchItem">
              <div class="title">商品信息：</div>
              <div class="searchContent">
                <el-input size="small" v-model="keywordLog" placeholder="请输入商品名称"></el-input>
              </div>
            </div>

            <el-button type="primary" size="small" @click="getSuccessAllGoods(1)">搜索</el-button>
          </div>
          <div class="bot">
            <el-table
              :cell-style="() => 'text-align:center'"
              :data="successAllGoods"
              :header-cell-style="{ 'background-color': '#F8F9FA', 'text-align': 'center' }"
            >
              <template slot="empty">
                <No />
              </template>
              <el-table-column label="商品信息">
                <template v-slot="{ row }">
                  <div class="goodsInfo">
                    <img :src="row.cover_img" alt="" />
                    <div class="left">
                      <div class="goodsName">{{ row.name }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="单价">
                <template v-slot="{ row }"> ￥{{ row.price }} </template>
              </el-table-column>
              <el-table-column label="操作">
                <template v-slot="{ row }">
                  <el-button v-if="!row.is_in" type="text" @click="improtChooseGoods(row)"
                    >加入直播间</el-button
                  >
                  <p v-else>已加入直播间</p>
                </template>
              </el-table-column>
            </el-table>
            <Paging
              :total="totalLog"
              :page="pageLog"
              :pageNum="pageNumsLog"
              @updatePageNum="updateDataLog"
            ></Paging>
          </div>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import { getDateformat } from '@/util/getDate.js'
import Paging from '@/components/paging'
let timer = null
export default {
  components: {
    Paging
  },
  data() {
    return {
      room_id: -1,
      roomInfo: {},
      firstTotal: 0,
      firstPage: 1,
      firstRow: 10,
      alreadyStartTimeHour: 0,
      alreadyStartTimeMin: 0,
      alreadyStartTimeSecond: 0,
      activeName: '0',
      chooseGoodsList: [],
      chooseGoddsFlag: false,
      successAllGoods: [],
      totalLog: 0,
      pageLog: 1,
      pageNumsLog: 10,
      liveGoodsList: [],
      page: 1,
      pageNums: 10,
      total: 0,
      dataPage: 1,
      dataRow: 10,
      dataTotal: 0,
      dataArr: [
        { name: '支付金额（元）', data: 0 },
        { name: '支付订单数', data: 0 },
        { name: '支付件数', data: 0 },
        { name: '支付人数', data: 0 },
        { name: '支付新客数（直播引流）', data: 0 }
      ],
      selectTime: '',
      orderList: [],
      keywordLog: ''
    }
  },
  created() {
    this.room_id = this.$route.query.room
    this.getRoomInfo(this.room_id)
    this.searchLiveGoods()
  },
  methods: {
    handleClose() {
      console.log(1111111111)
      this.searchLiveGoods()
    },
    // 查询此直播间绑定的商品
    searchLiveGoods() {
      let that = this
      this.$axios
        .post(this.$api.push.liveRoom.getLiveGoodList, {
          page: this.page,
          rows: this.pageNums,
          room_id: this.$route.query.room
        })
        .then((res) => {
          if (res.code === 0) {
            that.liveGoodsList = res.result.list
            that.total = res.result.total
          }
        })
    },
    getRoomInfo(room_id) {
      let that = this
      this.$axios.post(this.$api.push.liveRoom.getInfo, { room_id }).then((res) => {
        if (res.code === 0) {
          that.roomInfo = res.result
          that.roomInfo.starttimeDate = getDateformat(that.roomInfo.starttime)
          that.roomInfo.endtimeDate = getDateformat(that.roomInfo.endtime)
          if (that.roomInfo.live_status === 101) {
            timer = setInterval(this.dealTime, 1000)
          }
        } else {
          that.$message.error(res.msg)
        }
      })
    },
    dealTime() {
      let nowTime = Math.ceil(new Date().getTime() / 1000)
      if (nowTime >= this.roomInfo.endtime) {
        clearInterval(timer)
        return
      }
      if (this.roomInfo.starttime > nowTime) {
        return
      }
      // 已经开始直播的秒数
      let alreadyTime = nowTime - this.roomInfo.starttime
      this.alreadyStartTimeHour = Math.floor(alreadyTime / 60 / 60)
      if (this.alreadyStartTimeHour < 10)
        this.alreadyStartTimeHour = `0${this.alreadyStartTimeHour}`
      this.alreadyStartTimeMin = Math.floor((alreadyTime / 60) % 60)
      if (this.alreadyStartTimeMin < 10) this.alreadyStartTimeMin = `0${this.alreadyStartTimeMin}`
      this.alreadyStartTimeSecond = Math.floor(alreadyTime % 60)
      if (this.alreadyStartTimeSecond < 10)
        this.alreadyStartTimeSecond = `0${this.alreadyStartTimeSecond}`
    },
    // 点击导入商品
    importGoods() {
      if (this.roomInfo.live_status !== 101 && this.roomInfo.live_status !== 102) {
        this.$message.warning('直播间状态为未开始和直播中才能添加商品')
        return
      }
      this.pageLog = 1
      this.getSuccessAllGoods()
      this.chooseGoddsFlag = true
    },
    // 获取所有审核成功的商品
    getSuccessAllGoods(status) {
      let that = this
      if (status) {
        this.pageLog = 1
      }
      this.$axios
        .post(this.$api.push.liveRoom.goodsList, {
          page: this.pageLog,
          row: this.pageNumsLog,
          room_id: this.room_id,
          status: 2,
          name: status ? this.keywordLog : null
        })
        .then((res) => {
          if (res.code == 0) {
            that.successAllGoods = res.result.list
            that.totalLog = res.result.total
          }
        })
    },
    updateDataLog(val, status) {
      if (status == 0) {
        this.pageNumsLog = val
      } else {
        this.pageLog = val
      }
      // 重新获取数据
      this.getSuccessAllGoods()
    },
    updatefirstLog(val, status) {
      if (status == 0) {
        this.pageNums = val
      } else {
        this.page = val
      }
      // 重新获取数据
      this.searchLiveGoods()
    },
    updateSecondLog(val, status) {
      if (status == 0) {
        this.dataRow = val
      } else {
        this.dataPage = val
      }
      // 重新获取数据
      this.getLiveInfo()
    },
    updateData(val, status) {
      if (status == 0) {
        this.pageNums = val
      } else {
        this.page = val
      }
      // 重新获取数据
      this.searchLiveGoods()
    },
    // 导入选择的商品
    improtChooseGoods(row) {
      let that = this
      this.$axios
        .post(this.$api.push.liveRoom.addGoods, {
          ids: [row.wx_goods_id],
          roomId: this.room_id
        })
        .then((res) => {
          if (res.code === 0) {
            row.is_in = 1
            that.$message.success('添加成功')
          } else {
            that.$message.error(res.msg)
          }
        })
    },
    // 删除这个已上架的商品
    delLiveGoods(row) {
      let that = this
      this.$confirm('此操作将在此直播间下架该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return that.$axios.post(that.$api.push.liveRoom.deleteInRoom, {
            roomId: that.roomInfo.room_id,
            goodsId: row.goods_id
          })
        })
        .then((res) => {
          if (res.code === 0) {
            that.$message.success('下架成功')
            that.searchLiveGoods()
          } else {
            that.$message.error(res.msg)
          }
        })
        .catch()
    },
    // 切换分布页
    hanldeChange() {
      this.page = 1
      this.dataPage = 1
      if (this.activeName == '0') {
        this.searchLiveGoods()
      } else {
        this.getLiveInfo()
      }
    },
    // 获取直播详细信息
    getLiveInfo(status) {
      if (status) {
        this.dataPage = 1
      }
      this.selectTime = getDateformat(new Date().getTime() / 1000)
      this.$axios
        .post(this.$api.push.liveRoom.getGoodsData, {
          room_id: this.room_id,
          page: this.dataPage,
          rows: this.dataRow
        })
        .then((res) => {
          if (res.code === 0) {
            this.dataArr[0].data = res.result.livedata.pay_amount
            this.dataArr[1].data = res.result.livedata.order_num
            this.dataArr[2].data = res.result.livedata.pay_num
            this.dataArr[3].data = res.result.livedata.pay_person_num
            this.dataArr[4].data = res.result.livedata.pay_new_person
            this.orderList = res.result.goods.list
            this.dataTotal = res.result.goods.total
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    lookOrder(row) {
      this.$router.push(`/order/orderList?room=${this.room_id}&goods=${row.goods_id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  overflow: auto;
  background-color: #fff;
  .consoleTop {
    display: flex;
    margin: 30px 0;
    .left {
      display: flex;
      img {
        width: 200px;
        height: 160px;
      }
      .leftInfo {
        padding-top: 20px;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .roomTitle {
          font-size: 18px;
          font-weight: bold;
        }
        & > div > span {
          margin-right: 30px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      padding-left: 200px;
      & > div {
        height: 50px;
        margin-right: 50px;
        text-align: center;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .num {
        margin-top: 20px;
      }
    }
  }
  .liveStatus {
    width: 100%;
    background-color: #f8f9fa;
    margin: 30px 0 10px 0;
    padding: 30px 15px;
    font-size: 20px;
  }
}
.goodsItemInfo {
  display: flex;
  align-items: center;
  margin-left: 50px;
  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
}
.chooseGoodsBox {
  .searchBox {
    display: flex;
    align-items: center;
    margin: 15px 0 10px 0;
    box-sizing: border-box;
    background-color: #f8f9fa;
    padding: 0 15px;
    min-height: 60px;
    .searchItem {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .title {
        flex-wrap: nowrap;
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }
  .bot {
    .goodsInfo {
      display: flex;
      img {
        width: 60px;
        height: 60px;
      }
      .goodsName {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 20px;
        & > div {
          text-align: left;
        }
      }
    }
  }
}
.liveDataBox {
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .topData {
    .data {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .dataItem {
        flex-shrink: 0;
        width: 200px;
        height: 120px;
        background-color: #f9f9f9;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: 20px;
        margin-bottom: 20px;
        & > div:first-of-type {
          font-size: 14px;
        }
        & > div:last-of-type {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
  .botTable {
    .top {
      display: flex;
      align-items: center;
      .title {
        margin: 0;
      }
      .updateTime {
        margin: 0 20px;
      }
      .refash {
        cursor: pointer;
        color: #409eff;
      }
    }
    .bot {
      .el-table {
        margin-top: 20px;
      }
      .goodsInfo {
        display: flex;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          margin-left: 30px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
